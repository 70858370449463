export const byteToMegaByte = (byte: number) => byte / 1048576

export const isSafeInteger = (value: number) =>
  value < Number.MAX_SAFE_INTEGER && value > Number.MIN_SAFE_INTEGER

/**
 * 数値を末尾Kの文字列にする
 * @param value
 * @param threshold
 */
export const convertNumberToK = (value: number, threshold?: number): string => {
  // 正常に扱える最大桁数、最小桁数を超えていた場合エラー
  if (!isSafeInteger(value)) {
    throw new Error('not safe integer')
  }

  const div = value / 1000

  // valueが1000以下だった場合
  if (div < 1) return value.toString()
  // valueが99900以上だった場合
  if (typeof threshold !== 'undefined' && div > threshold) {
    return `${threshold}K+`
  }

  // ex: 1.2K
  return `${Math.floor(div * 10) / 10}K`
}

/**
 * 数値を末尾+の文字列にする
 * @param value
 * @param threshold
 */
export const convertNumberToPlus = (
  value: number,
  threshold?: number
): string => {
  // 正常に扱える最大桁数、最小桁数を超えていた場合エラー
  if (!isSafeInteger(value)) {
    throw new Error('not safe integer')
  }

  if (typeof threshold !== 'undefined' && value >= threshold) {
    return `${threshold}+`
  } else {
    return value.toString()
  }
}
